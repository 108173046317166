import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { quizTypeEnum } from "../App";

export default function Quiz(props) {

    const [text, setText] = React.useState('');
    const [choice, setChoice] = React.useState(-1);
    const [score, setScore] = React.useState(-1);
    const [clicked, setClicked] = React.useState(-1);
    const [activeStep, setActiveStep] = React.useState(0);
    const [finished, setFinished] = React.useState(false);
    const [haloEffectAnswer, setHaloEffectAnswer] = React.useState({
        value: [
            {
                value: 0,
                label: "A. Forte personnalité"
            },
            {
                value: 0,
                label: "B. Faible personnalité"
            },
            {
                value: 0,
                label: "C. Pas d'avis"
            },
        ]
    });

    const name = props.quizName;
    const title = props.title;
    const answer = props.answer;
    const questions = props.questions;
    const description = props.description;
    const explanation = props.explanation;
    const steps = props.steps;
    const finalStep = props.finalStep;

    document.title = `Quiz Sceptique : ${name}`;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (choice !== -1) {
            sendResponse().then(res => console.log('done.'));
        }
    };

    const onResponse = (event) => {
        if (Boolean(event.value)) {
            setChoice(event.value * 1);
        }
        if (Boolean(event.text)) {
            setText(event.text || '');
        }
        if (Boolean(event.score)) {
            setScore(event.score || '');
        }
        if (Boolean(event.clicked)) {
            setClicked(event.clicked);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setChoice(-1);
        setScore(-1);
        setText('');
        props.nextQuiz();
    };

    const sendResponse = async () => {
        let message = title;
        let blocks = questions.map((question, index) => {
            let value = `${question.label}`;

            if (props.type === quizTypeEnum.HaloEffect) {
                return {
                    type: "section",
                    block_id: `section${question.value}`,
                    text: {
                        type: "mrkdwn",
                        text: `*${haloEffectAnswer.value[index].label}* : ${((haloEffectAnswer.value[index].value * 100) / 11).toFixed(2)} %`
                    }
                }
            } else {
                if (question.value === choice) {
                    value = `${question.value === answer ? ':white_check_mark:' : ':x:'} *${value}*`
                } else if (question.value === answer) {
                    value = `:white_check_mark: *${value}*`
                }
                return {
                    type: "section",
                    block_id: `section${question.value}`,
                    text: {
                        type: "mrkdwn",
                        text: value
                    }
                }
            }

        });
        if (Boolean(text)) {
            blocks.push({
                type: "section",
                block_id: `section_text`,
                text: {
                    type: "mrkdwn",
                    text: text
                }
            });
        }
        if (Boolean(score) && score !== -1) {
            blocks.push({
                type: "section",
                block_id: `section_score`,
                text: {
                    type: "mrkdwn",
                    text: `Score : ${score}`
                }
            });
        }
        let payload = {
            "channel": "#quiz",
            "username": "Quiz",
            "text": message,
            "blocks": [{
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": `*${message}*`
                }
            }, ...blocks]
        };
        console.log(JSON.stringify(payload))
        let result = await fetch('/api/slack/message', {
            headers: {'Content-Type': "application/json"},
            method: "POST",
            body: JSON.stringify(payload)
        })
        console.log('result', result);
    }

    const isDisabled = () => {
        return typeof steps[activeStep].disabled === 'function' && steps[activeStep].disabled({ text, choice, score, clicked, finished });
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel {...labelProps}>{step.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    {props.type !== quizTypeEnum.HaloEffect &&
                        <Typography variant={"div"} sx={{ mt: 2, mb: 1 }}>
                            Vous avez choisi la réponse <strong>"{questions[choice].label}"</strong>
                            {questions[choice].value === answer && <Typography color={questions[choice].other ? "orange" : "green"}>
                                Votre choix est {questions[choice].other ? text : "le bon"}<br />
                                {explanation.right}
                            </Typography>}
                            {questions[choice].value !== answer && <Typography color={"red"}>
                                La bonne est réponse était <strong>"{questions[answer].label}"</strong><br />
                                {explanation.wrong}
                            </Typography>}
                        </Typography>
                    }
                    <br />
                    {finalStep.component({ score, haloEffectAnswer })}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button disabled={finalStep.disabled()} onClick={handleReset}>{finalStep.next}</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ mt: 4 }} />
                    {steps[activeStep].component({ onChange: onResponse, description, questions, name, score, clicked, handleBack, handleNext, setFinished, setHaloEffectAnswer, haloEffectAnswer, setChoice })}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={!steps[activeStep].hasBack}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            {steps[activeStep].previous}
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext} disabled={isDisabled()}>
                            {steps[activeStep].next}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}