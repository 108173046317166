import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import {TextField} from "@mui/material";

export default function QuizQuestion(props) {
    const [value, setValue] = React.useState('');
    const [other, setOther] = React.useState(false);
    const [text, setText] = React.useState('');

    const questions = props.questions;

    const handleRadioChange = (event) => {
        let index = event.target.value * 1;
        setOther(Boolean(questions[index].other));
        setValue(event.target.value);
        props.onChange({value: event.target.value, text})
    };

    const handleText = (event) => {
        setText(event.target.value);
        props.onChange({text: event.target.value, value})
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.onChange({value, text})
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl sx={{ m: 3 }} variant="standard">
                <FormLabel id="demo-error-radios">{props.title}</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    value={value}
                    onChange={handleRadioChange}
                >
                    {questions.map(question => {
                        return <FormControlLabel key={question.value} value={question.value} control={<Radio />} label={Boolean(question.other) ? question.otherLabel : question.label} />
                    })}
                </RadioGroup>
                {other && <TextField onChange={handleText}/>}
                <FormHelperText>Choisissez une réponse</FormHelperText>
            </FormControl>
        </form>
    );
}
