import * as React from 'react';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import Quiz from "./components/Quiz";
import { AppBar, Box, Card, CardContent, IconButton, Toolbar } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Typography from "@mui/material/Typography";
import CardImageList from "./views/cardImage/CardImageList";
import QuizQuestion from "./components/QuizQuestion";
import QuizPresentation from "./components/QuizPresentation";
import FlipCards from "./views/flipCard/FlipCards";
import { useLocation } from "react-router-dom";
import HaloEffect from './views/haloEffect/HaloEffect';

export const quizTypeEnum = {
    FlipCard: "FlipCard",
    HaloEffect: "HalloEffect",
    RuleCheck: "RuleCheck"
}

const cards = ['JS', 'KH', 'QC', 'JD', 'KS', 'QH', 'JC', 'KD', 'QS', 'JH', 'KC', 'QD']
const firstPart = ['JS', 'KH', 'QC', 'JD', 'KS', 'QH'].sort(() => Math.random() - 0.5)
const secondPart = ['JC', 'KD', 'QS', 'JH', 'KC', 'QD'].sort(() => Math.random() - 0.5)

const flipCards = [
    {
        back: 2,
        front: 'E',
        value: true,
        readonly: false
    },
    {
        back: 3,
        front: 'K',
        value: true,
        readonly: false
    },
    {
        back: 4,
        front: 'U',
        value: false,
        readonly: false
    },
    {
        back: 7,
        front: 'A',
        value: false,
        readonly: false
    },
    {
        back: 5,
        front: 'X',
        value: true,
        readonly: false
    },
]

const flippedCards = [
    {
        back: 2,
        front: 'E',
        value: false,
        readonly: true
    },
    {
        back: 3,
        front: 'K',
        value: false,
        readonly: true
    },
    {
        back: 4,
        front: 'U',
        value: true,
        readonly: true
    },
    {
        back: 7,
        front: 'A',
        value: true,
        readonly: true
    },
    {
        back: 5,
        front: 'X',
        value: false,
        readonly: true
    },
]

const QUIZ_INDEX = 'quiz.index';

function useQuery() {
    const { search } = useLocation();

    let queryString = decodeURI(search.substring(1));

    let params = {},
        queries,
        temp,
        i,
        l;

    // Split into key/value pairs
    queries = queryString.split("&");

    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
        temp = queries[i].split("=");
        params[temp[0]] = temp[1];
    }

    return 0;
}

function App() {

    const QUIZ = [
        {
            type: quizTypeEnum.FlipCard,
            quizName: "Quiz 1",
            title: "Biais de selection",
            description: <>
                <Typography variant={"h5"}>Dans les écrans qui vont suivre, nous vous montrerons 6 cartes parmi
                    lesquelles vous devrez mémoriser une carte.</Typography>
                <br />
                A la fin de ce quiz, vous serez amené à répondre à une question sur les cartes.
                <br />
                <br />
                <strong>
                    Si vous êtes sur votre smartphone, nous vous conseillons de l'orienter en mode paysage pour une
                    bonne présentation des pages.</strong>
            </>,
            questions: [
                {
                    value: 0,
                    label: "A. Il y a un programme qui a suivi mon regard pour connaitre mon choix."
                },
                {
                    value: 1,
                    label: "B. C'est un hasard pur."
                },
                {
                    value: 2,
                    label: "C. J'ai cliqué sur la carte choisie."
                },
                {
                    value: 3,
                    label: "D. Je ne connais pas la raison."
                },
                {
                    value: 4,
                    label: "E. Toutes les cartes ont été remplacées.",
                    otherLabel: "E. Autre à préciser.",
                    other: true
                },
            ],
            answer: 4,
            explanation: {
                right: <>
                    Dans ce quiz nous essayons de mettre en évidence le biais de sélection. La plupart des gens
                    ne vont pas choisir la bonne réponse à cause de ce biais.
                </>,
                wrong: <>
                    Vous avez été victime d'un biais qu'on appel Biais de sélection. Ce biais nous amène à se
                    focaliser uniquement sur les détails qui nous intéressent.
                </>
            },
            steps: [
                {
                    label: '',
                    previous: 'Précédent',
                    next: 'Suivant',
                    component: (opts) => <QuizPresentation {...opts} />
                },
                {
                    label: '',
                    hasBack: true,
                    previous: 'Précédent',
                    next: 'Suivant',
                    disabled: (opts) => opts.clicked === -1,
                    component: (opts) => <CardImageList key={"first"}
                        {...opts}
                        items={firstPart}
                        height={200}
                        timer={3}
                        title={
                            <div>
                                Choisissez une carte et mémorisez la, nous allons la
                                faire disparaitre.
                                <br />
                                Vous avez 3 secondes pour faire votre choix après avoir
                                cliquer sur le bouton <strong>AFFICHER LES
                                    CARTES</strong>.
                                <br />
                                Une fois le choix fait, cliquez sur le bouton Suivant.
                            </div>
                        } />
                },
                {
                    label: '',
                    hasBack: false,
                    previous: 'Précédent',
                    next: 'Suivant',
                    component: (opts) => <CardImageList key={"second"}
                        {...opts}
                        items={secondPart.slice(1)}
                        readonly={true}
                        displayed={true}
                        height={200}
                        title={<div><strong>Tadaaaaaaaa !</strong> Et votre carte ...
                            disparuuuuu !</div>} />
                },
                {
                    label: '',
                    previous: 'Précédent',
                    next: 'Terminer',
                    disabled: (opts) => opts.choice === -1,
                    hasBack: false,
                    component: (opts) => <QuizQuestion {...opts} title={"Savez-vous pourquoi votre choix a disparu ?"} />
                },
            ],
            finalStep: {
                label: '',
                hasBack: false,
                next: 'Quiz Suivant',
                disabled: opts => false,
                component: (opts) => <CardImageList {...opts}
                    items={cards}
                    readonly={true}
                    displayed={true}
                    height={400}
                    title={<strong>Voici l'ensemble des cartes choisies.</strong>} />
            },
        },
        {
            type: quizTypeEnum.RuleCheck,
            quizName: "Quiz 2",
            title: "Biais de confirmation",
            description: <>
                <Typography variant='h5'>Le but de ce jeu est de vérifier si la règle proposée est vraie ou
                    non.</Typography>
                <br />
                <br />
                A l'étape suivante vous allez voir quatre cartes et une règle énoncée sur ces cartes, puis à l'étape
                d'après
                une question vous sera posée sur la règle énoncée.
                <br />
                <br />
                <strong>Si vous êtes sur votre téléphone mettez le en mode paysage pour la bonne présentation des
                    pages.</strong>
            </>,
            questions: [
                {
                    value: 0,
                    label: "A. Oui"
                },
                {
                    value: 1,
                    label: "B. Non."
                },
            ],
            answer: 1,
            explanation: {
                right: <>
                    En effet la règle n'est pas valide on peut la vérifier en essayant de la réfuter.<br />
                    Il y a une carte qui contredit la règle énoncée.
                </>,
                wrong: <>
                    Vous avez essayé de confirmer la règle ce qui a fait que vous vous êtes trompé.<br />
                    La bonne réponse c'est que la règle n'est pas valide car il y a une carte qui contredit la règle.
                </>
            },
            steps: [
                {
                    label: '',
                    previous: 'Précédent',
                    next: 'Suivant',
                    component: (opts) => <QuizPresentation {...opts} />
                },
                {
                    label: '',
                    hasBack: false,
                    previous: 'Précédent',
                    next: 'Suivant',
                    disabled: (opts) => opts.score === -1,
                    component: (opts) => <div>
                        <strong>Règle : S'il y a UNE VOYELLE sur une des faces de la carte, alors il y a un NOMBRE PAIR
                            sur l'autre face.</strong>
                        <br />
                        <FlipCards cards={flipCards} {...opts} />
                        <div>
                            Pour vérifier la règle, vous pouvez cliquer sur une carte pour la retourner.
                            <br />
                            Lorsque vous révélez une carte, votre score est diminué de 1 point.
                            <br />
                            Vous devez donc vérifier si la règle est valide ou non en un minimum de coup afin de
                            maximiser vos points.
                            <br />
                            Quand vous êtes sûr-e d'avoir la réponse, vous pouvez passer à l'étape suivante pour
                            répondre à la question posée.
                        </div>
                    </div>
                },
                {
                    label: '',
                    previous: 'Précédent',
                    next: 'Terminer',
                    disabled: (opts) => opts.choice === -1,
                    hasBack: false,
                    component: (opts) => <QuizQuestion {...opts} title={"La règle énoncée est-elle valide ?"} />
                },
            ],
            finalStep: {
                label: '',
                hasBack: true,
                previous: 'Précédent',
                next: 'Suivant',
                disabled: (opts) => false,
                component: (opts) => <div>
                    <strong>Voici toutes les cartes retournées. <br />L'avant dernière carte ne respecte pas la règle énoncée.</strong>
                    <FlipCards cards={flippedCards} readonly={true} {...opts} />
                </div>
            },
        },
        {
            type: quizTypeEnum.HaloEffect,
            quizName: "Quiz 3",
            title: "Effet Halo",
            description: <>
                <Typography variant='h5'>Le but de ce jeu est de juger la personnalité d'une personne en se basant sur votre première impression</Typography>
                <br />
                <br />
                A l'étape suivante vous allez faire defiler des photos de personnes et donner votre avis sur la personnalité de la personne: <strong>Forte, Faible, pas d'avis</strong>
                <br />
                <br />
                <strong>Si vous êtes sur votre téléphone mettez le en mode paysage pour la bonne présentation des
                    pages.</strong>
            </>,
            questions: [
                {
                    value: 0,
                    label: "A. Faible personnalité"
                },
                {
                    value: 1,
                    label: "B. Forte personnalité"
                },
                {
                    value: 2,
                    label: "C. Pas d'avis"
                },
            ],
            answer: undefined,
            explanation: {
                right: <>
                    Merci pour vos reponses.
                </>,
                wrong: <>
                    Merci pour vos reponses.
                </>
            },
            steps: [
                {
                    label: '',
                    previous: 'Précédent',
                    next: 'Suivant',
                    component: (opts) => <QuizPresentation {...opts} />
                },
                {
                    label: '',
                    hasBack: false,
                    previous: 'Précédent',
                    next: 'Suivant',
                    disabled: (opts) => opts.finished === false,
                    component: (opts) => <HaloEffect {...opts} />
                }
            ],
            finalStep: {
                label: '',
                hasBack: true,
                previous: 'Précédent',
                next: 'Recommencer',
                disabled: (opts) => false,
                component: (opts) => <Card style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', minHeight: 400 }}>
                    <CardContent>
                    <strong>Merci pour vos reponses. <br /></strong>
                    <Typography>Vous n'êtes pas sensé pourvoir connaitre la personnalité d'un individu juste avec votre première impression visuel !<br />
                    Mais ceci est le cas très souvent. C'est ce qu'on appelle <strong>effet Halo</strong></Typography>
                    <br />
                    <Typography>
                        L'effet de halo se produit lorsqu'une première impression basée sur un seul trait d'un objet évalué est généralisée à d'autres aspects, parfois non reliés, de cet objet. Ce biais cognitif se manifeste lorsque les individus jugent rapidement une personne en se basant sur les impressions favorables ou défavorables d'une de ses caractéristiques. L'effet de halo n'est pas limité aux individus et peut également se produire lorsque nous jugeons certaines choses telles que des entreprises ou des lieux. Le sentiment suscité par la première impression est transféré aux autres facettes de l'objet qui n'ont pas été réellement explorées par l'individu.
                        Même si cet effet est plus fréquemment associé à un biais positif, il est également possible qu'un biais négatif en résulte, selon la nature de l'impression initiale.
                        Le terme effet de halo vient du fait qu'un seul trait empreint entièrement le jugement, tout comme le halo souvent dépeint dans l'art religieux empreint entièrement une personne.
                    </Typography>
                    </CardContent>
                </Card>
            },
        }
    ];

    const query = useQuery();

    const storedItem = localStorage.getItem(QUIZ_INDEX);
    let storedIndex = 0;
    if (query.index) {
        storedIndex = query.index * 1;
    } else if (typeof storedItem === 'string') {
        storedIndex = Number.parseInt(storedItem);
    }

    let queryIndex = storedIndex;

    if (typeof queryIndex !== 'number' || queryIndex >= QUIZ.length) {
        queryIndex = 0;
    }
    const [quizIndex, setQuizIndex] = useState(queryIndex);

    const quiz = QUIZ[quizIndex];

    const nextQuiz = () => {
        let index = (quizIndex + 1) % QUIZ.length;
        localStorage.setItem(QUIZ_INDEX, `${index}`);
        setQuizIndex(index);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Quiz Sceptique
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box mt={5} />
            <Container maxWidth="md">
                <Quiz questions={quiz.questions}
                    title={quiz.title}
                    quizName={quiz.quizName}
                    answer={quiz.answer}
                    description={quiz.description}
                    explanation={quiz.explanation}
                    steps={quiz.steps}
                    finalStep={quiz.finalStep}
                    nextQuiz={nextQuiz}
                    type={quiz.type}
                />
            </Container>
            <Box mt={5} />
        </React.Fragment>
    );
}

export default App;
