import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Button} from "@mui/material";
import CardImage from "./CardImage";

//const cards = ['JS', 'KH', 'QC', 'JD', 'KS', 'QH', 'JC', 'KD', 'QS', 'JH', 'KC', 'QD']
//const firstPart = ['JS', 'KH', 'QC', 'JD', 'KS', 'QH']
//const secondPart = ['JC', 'KD', 'QS', 'JH', 'KC', 'QD']

export default function CardImageList(props) {
    const [timeout, setTimeout] = useState(false);
    const [displayed, setDisplayed] = useState(props.displayed);
    const [readonly, setReadonly] = useState(props.readonly);
    const [timer, setTimer] = useState(0);

    const [itemData, setItemData] = useState((props.items).map((card, index) => {
        return {
            id: index,
            title: `${card}`,
            img: `/cards/${card}.svg`,
            clicked: false
        };
    }));

    const handleItemClicked = (item) => {
        let items = itemData.map((card) => {
            card.clicked = item.clicked && item.id === card.id;
            return card;
        });
        setItemData(items);
    }

    const handleDisplay = () => {
        setDisplayed(true);
        props.onChange({clicked: 1})
        if (props.timer > 0) {
            let handler = window.setTimeout(() => {
                setTimeout(true);
                setReadonly(true);
                //props.handleNext();
            }, props.timer * 1000);
            setTimer(handler);
        }
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
    }, [timer]);

    return (
        <>
            <div>{props.title}</div>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 0.5,
                        width: 120,
                        height: 150,
                    }
                }}
            >
                {!displayed &&
                <div style={{textAlign: 'center', width: '100%', height: '100%', marginTop: 10}}>
                    <Button variant="contained" onClick={handleDisplay}>Afficher les cartes</Button>
                </div>
                }
                {displayed && itemData.map((item) => (
                    <CardImage key={item.img}
                               timeout={timeout}
                               readonly={readonly}
                               item={item}
                               itemClicked={handleItemClicked}/>
                ))}
            </Box>
        </>
    );
}
