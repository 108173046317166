import * as React from 'react';
import {useState} from 'react';
import {Card} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function FlipCard(props) {
    const [flipped, setFlipped] = useState(false);
    const [value, setValue] = useState(props.value);

    const handleValue = () => {
        if(!flipped)
            setFlipped(!flipped);
        setValue(!value);
        if(!(props.readonly || flipped))
            props.onCardFlipped();
    }
    return <Card onClick={handleValue} style={{textAlign: 'center', cursor: 'pointer'}}>
        <Typography variant={"h1"}>
            {value ? props.front : props.back}
        </Typography>
    </Card>
}