import * as React from 'react';
import {Card} from "@mui/material";

export default function CardImage(props) {

    const item = props.item;

    const handleClick = () => {
        item.clicked = !item.clicked
        props.itemClicked(item)
    }

    return <Card style={{cursor: 'pointer', border: item.clicked ? 'solid green' : "none"}}>
        <img width={120}
             height={150}
             src={`${props.timeout ? '/cards/back-blue.svg' : item.img}`}
             srcSet={`${props.timeout ? '/cards/back-blue.svg' : item.img}`}
             alt={item.title}
             loading="lazy"
             onClick={props.readonly ? null : handleClick}
        />
    </Card>
}