import * as React from 'react';
import { useState } from 'react';
import Slide from '@mui/material/Slide';
import { Box, Button, Card, CardMedia, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, List, ListItemText, Skeleton, Typography } from "@mui/material";

export default function HaloEffect(props) {

    const [index, setIndex] = useState(1);
    const [slideIn, setSlideIn] = useState(true);
    const [formValue, setFormValue] = useState([false, false, false]);
    const [loading, setLoading] = useState(false);
    // To prevent multiple click
    const [disabled, setDisabled] = useState(false);

    const saveResponse = (_index, value) => {
        setDisabled(true);
        props.setChoice(0);
        setSlideIn(false);
        const _value = formValue.map((v, i) => {
            return i === value;
        });
        setFormValue(_value)

        props.setHaloEffectAnswer({
            value: [
                {
                    value: value === 0 ? props.haloEffectAnswer.value[0].value + 1 : props.haloEffectAnswer.value[0].value,
                    label: "A. Faible personnalité"
                },
                {
                    value: value === 1 ? props.haloEffectAnswer.value[1].value + 1 : props.haloEffectAnswer.value[1].value,
                    label: "B. Forte personnalité"
                },
                {
                    value: value === 2 ? props.haloEffectAnswer.value[2].value + 1 : props.haloEffectAnswer.value[2].value,
                    label: "C. Pas d'avis"
                },
            ]
        });
    }

    return <Slide timeout={1000} direction={slideIn ? "left" : 'right'} in={slideIn} mountOnEnter unmountOnExit onExited={() => {
        console.log("Exited", index);
        setSlideIn(true);
        setFormValue([false, false, false]);
        setDisabled(false);
        if (index <= 11) {
            setIndex(index + 1);
        } else {
            props.setFinished(true);
        }
    }}>
        <Card style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', minHeight: 400 }}>
            {index <= 11 ?
                <>
                    {loading ?
                        <Skeleton variant="rectangular" width={"50%"} height={200} /> :
                        <CardMedia
                            component="img"
                            sx={{ height: 200, width: 'auto' }}
                            onLoadStart={() => setLoading(true)}
                            onLoad={() => setLoading(false)}
                            image={"/Photos_effet_Halo/" + index + ".png"}
                        />}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                        <FormControl form='choice' sx={{ m: 3 }} component="fieldset" variant="standard" disabled={disabled}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={formValue[0]} onChange={() => saveResponse(index, 0)} name="0" />
                                    }
                                    label="Faible personnalité"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={formValue[1]} onChange={() => saveResponse(index, 1)} name="1" />
                                    }
                                    label="Forte personnalité"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={formValue[2]} onChange={() => saveResponse(index, 2)} name="2" />
                                    }
                                    label="Pas d'avis"
                                />

                            </FormGroup>
                            <FormHelperText>Votre première impression </FormHelperText>
                        </FormControl>

                    </Box>
                </>
                : <>
                    <Typography align='center'>Merci pour vos reponses :)</Typography>
                    <Box>
                        <List>
                            {props.haloEffectAnswer.value.map((_response, _index) => (
                                <ListItemText key={`${_index}`} primary={`${_response.label}`} secondary={`${(_response.value * 100 / 11).toFixed(2)} %`} />
                            ))
                            }
                        </List>
                    </Box>
                    <Button variant="contained" onClick={props.handleNext} sx={{ margin: 8 }}>
                        Suivant
                    </Button>
                </>
            }
        </Card >
    </Slide>
}