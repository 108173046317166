import * as React from 'react';
import {useState} from 'react';
import {Box, Typography} from "@mui/material";
import FlipCard from "./FlipCard";

export default function FlipCards(props) {
    const [score, setScore] = useState(5);
    const cards = props.cards;
    const onCardFlipped = () => {
        let value = score - 1;
        setScore(value);
        props.onChange({score: value})
    }
    let displayScore = props.readonly ? props.score : score;
    return <div>
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: 128,
                    height: 128,
                }
            }}
        >
            {cards.map((card, index) => {
                return <FlipCard key={index} {...card} readonly={props.readonly} onCardFlipped={onCardFlipped}/>
            })}
            <br/>
        </Box>
        <Typography variant={"strong"} color={displayScore < 3 ? "red" : "green"}>Score : {displayScore}</Typography>
    </div>
}