import * as React from 'react';
import {Typography} from "@mui/material";

export default function QuizPresentation(props) {

    return (
        <div>
            <Typography variant={"h2"}>{props.name}</Typography>
            {props.description}
        </div>
    );
}
